<div class="contenedorModal">
    <div class="cabezal">
        <div class="boton_cerrar">
            <i class="fas fa-times" mat-dialog-close>X</i>
        </div>
        <h3 class="encabezado">RESTABLECER CONTRASEÑA</h3>
    </div>
    <div class="textoDelModal">
        <p>Nueva contraseña</p>
        <form class="formulario">
            <mat-form-field appearance="legacy">
                <mat-label>Ingrese una nueva contraseña</mat-label>
                <input type="password" matInput placeholder="">
            </mat-form-field>

            <mat-form-field appearance="legacy">
                <mat-label>Confirmar contraseña</mat-label>
                <input type="password" matInput placeholder="">
            </mat-form-field>
        
        </form>
    </div>
    <div class="buttonModal">
        <button mat-raised-button type="button" color="primary">Aceptar</button>
        <button mat-raised-button type="button" mat-dialog-close>Cancelar</button>

    </div>

</div>