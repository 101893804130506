import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-disponible',
  templateUrl: './no-disponible.component.html',
  styleUrls: ['./no-disponible.component.scss']
})
export class NoDisponibleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
