<div class="contenedorModal">
    <div class="cabezal">
        <div class="boton_cerrar">
            <!-- <i class="fas fa-times" mat-dialog-close>X</i> -->
            <button class="main__btn-close" mat-dialog-close mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <h3 class="encabezado">PRODUCTOS NO DISPONIBLES</h3>
    </div>
    <div class="textoDelModal">
        <p>Algunos de tus productos los tenemos agotados. Se quitarán automáticamente de tu carrito de compras.</p>
              
    </div>
    <div class="buttonModal">
        <button mat-raised-button type="button" class="btn btn-primary modiBton">Aceptar</button>


    </div>

</div>