<footer>
    <div class="container">
    
        <section class="footer-web">
            <div class="columna">

                <div class="querre">
                    <img src="../../../../assets/images/unnamed.png" alt="">
                </div>
    
                <div class="columna__telefonosCorreo">
                    <mat-icon class="mr-2 icono" style="margin-right: 10px;">phone</mat-icon>

                    <div class="columna__texto">
                        <span>(55)5515-0524</span> <br>
                        <span>(55)5515-3788</span><br>
                        <span>(55)5277-0350 con 10 líneas</span>
                    </div>

                    <mat-icon class="mr-2 icono" style="margin-right: 10px;">mail</mat-icon>

                    <div class="columna__texto d-flex align-items-end">
                        <span>ventasenlinea@figueacero.com.mx</span>
                    </div>

                </div>
    
                <!-- <div class="columna__item">
                    <mat-icon class="mr-2">mail</mat-icon>
                    <div class="columna__texto">
                        <span>ventasenlinea@figueacero.com.mx</span>
                    </div>
                </div> -->

               
    
            </div>
    
            <!-- col2 -->
            <div class="columna">
                
                <div class="columna__item">
                    <mat-icon class="mr-2 icono">location_on</mat-icon>
                    <div class="columna__texto">
                        <span class="modi1">Puente de la Morena No. 35, Colonia Tacubaya C.P. 11870, Alcaldía Miguel Hidalgo, Ciudad de México.</span> <br>
                        <span>Entre Revolución y Patriotismo a la altura de Viaducto.</span>
                    </div>
                </div>
    
            </div>
        </section>
    
    
        <section class="footer-mobile">
    
        </section>
    </div>
</footer>