import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-restrablecer-contra',
  templateUrl: './restrablecer-contra.component.html',
  styleUrls: ['./restrablecer-contra.component.scss']
})
export class RestrablecerContraComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
