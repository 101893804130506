<div class="contenedorModal">
    <div class="cabezal">
        <div class="boton_cerrar">
            <!-- <i class="fas fa-times" mat-dialog-close>X</i> -->
            <button class="main__btn-close" mat-dialog-close mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <h3 class="encabezado">RECUPERAR MI CUENTA</h3>
    </div>
    <div class="textoDelModal">
        <p>Ingresa tu correo electrónico</p>
        <form class="formulario">
            <mat-form-field appearance="legacy">
                <mat-label>E-mail</mat-label>
                <input type="email" matInput placeholder="Ingrese su e-mail">
            </mat-form-field>
        </form>
    </div>


    <div class="buttonModal">
        <button mat-raised-button type="button" color="primary">Aceptar</button>
        <button mat-raised-button type="button">Cancelar</button>
    </div>


    <div class="textoDelModal2">
        <p>Te hemos enviado un correo para recuperar tu cuenta.</p>
    </div>

    <div class="buttonModal">
        <button mat-raised-button type="button" color="primary">Aceptar</button>


    </div>

</div>