import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SigninComponent } from "./signin/signin.component";
import { SignupComponent } from "./signup/signup.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "src/app/material.module";
import { AlertAgeComponent } from "./alert-age/alert-age.component";
import { SharedModule } from "../shared.module";
import { ModalGenericComponent } from "./modal-generic/modal-generic.component";

const modals = [
  SigninComponent,
  SignupComponent,
  AlertAgeComponent,
  ModalGenericComponent,
];

@NgModule({
  declarations: [...modals],
  imports: [CommonModule, SharedModule, ReactiveFormsModule, MaterialModule],
  entryComponents: [...modals],
  exports: [...modals],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ModalsModule {}
