import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { pluck, catchError } from "rxjs/operators";
import { of, Observable } from "rxjs";
import {
  Product,
  Categoria,
  GenericResponse,
  ProductosDummy,
  ProductDetail,
  GenericResp,
} from "../models/products.model";

@Injectable({
  providedIn: "root",
})
export class ProductsService {
  urlLiquidacionNuevosProd = environment.urlAWS + "/";

  constructor(public http: HttpClient) {}

  productsList(): Observable<Product[]> {
    return this.http.get<Product[]>(environment.url + "productos").pipe(
      pluck("resultDto", "sDetalle"),
      catchError<Product[], Observable<Product[]>>((err) => of([]))
    );
  }

  getProductsByTipoAndByCategory(
    tipo: number,
    category: number,
    page: number
  ): Observable<Product[]> {
    const req = {
      iTipoProducto: tipo,
      iCategoria: category,
      pagina: page,
      regxpag: 30,
    };
    const params = new HttpParams({ fromObject: req });
    return this.http.get<Product[]>(`${environment.urlAWS}/productos`, {
      params,
    });
  }

  getProductsByTipoAndByCategoryText(
    type: number,
    category: number,
    page: number,
    textSearch: string
  ): Observable<Product[]> {
    const req = {
      iTipoProducto: type,
      iCategoria: category,
      sCriterio: textSearch,
      pagina: page,
      regxpag: 30,
    };
    const params = new HttpParams({ fromObject: req });
    return this.http.get<Product[]>(`${environment.urlAWS}/productos`, {
      params,
    });
  }

  getProductById(id: number): Observable<ProductDetail[]> {
    return this.http.get<ProductDetail[]>(
      environment.urlAWS + `/productos/${id}`
    );
  }

  getCategorias(): Observable<Categoria[]> {
    return this.http.get<Categoria[]>(environment.urlAWS + "/categorias");
  }

  getVariantes(idProducto: number): Observable<ProductDetail[]> {
    return this.http.get<ProductDetail[]>(
      `${environment.urlAWS}/productos/variantes/${idProducto}`
    );
  }

  getProductosLiquidacion(
    viewname: string,
    filtro: string,
    page = 10
  ): Observable<any> {
    return this.http.get(
      `${
        this.urlLiquidacionNuevosProd
      }view?regxpag=${10}&pagina=${page}&viewname=${viewname}&filtro=${filtro}`
    );
  }
}
