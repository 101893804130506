import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Coupon } from "src/app/models/coupon.model";
import { CouponService } from "src/app/services/coupon.service";

@Component({
  selector: "app-mis-cupones",
  templateUrl: "./mis-cupones.component.html",
  styleUrls: ["./mis-cupones.component.scss"],
})
export class MisCuponesComponent implements OnInit, OnDestroy {
  $subs = new Subject();
  coupons: Coupon[] = [];
  controlCupon = new FormControl(null, Validators.required);

  constructor(private couponService: CouponService, public dialogRef: MatDialogRef<MisCuponesComponent>) {}

  ngOnInit() {
    this.getCoupons();
  }

  ngOnDestroy(): void {
    this.$subs.next();
    this.$subs.complete();
  }

  private getCoupons() {
    this.couponService
      .getCoupons()
      .pipe(takeUntil(this.$subs))
      .subscribe((coupons) => {
        this.coupons = coupons;
      });
  }

  saveCoupon(): void {
    this.dialogRef.close(this.controlCupon.value);
  }
}
