<div class="contenedorModal">
    <div class="cabezal">
        <div class="boton_cerrar">
            <!-- <i class="fas fa-times" mat-dialog-close>X</i> -->
            <button class="main__btn-close" mat-dialog-close mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <h3 class="encabezado">ELIGE TU UBICACIÓN</h3>
    </div>
    <div class="textoDelModal">

        <ng-container *ngIf="!isLogged">
            <div class="parteUno">
                <p>Selecciona una dirección de envío para</p>
                <p>ver las opciones de envío</p>
                <button mat-raised-button type="button" class="btn btn-primary" (click)="iniciarSesion()">Iniciar
                    sesión</button>
            </div>
        </ng-container>

        <ng-container *ngIf="isLogged">
            <div class="parteDos">
                <p>Las velocidades de envío pueden variar</p>
                <p>según la dirección</p>
                <div class="cajaDireccion mb-2" *ngFor="let address of addressList">
                    <div>
                        <span>{{address.sColonia}} - {{address.sDelegacion}} - </span> <span
                            class="modiCP">{{address.iCodigoPostal}}</span>
                    </div>
                    <div>
                        <button mat-raised-button color="primary" class="py-0"
                            *ngIf="codigoPostal.value != address.iCodigoPostal"
                            (click)="selectCP(address)">Seleccionar</button>
                        <span class="modiDirec" *ngIf="codigoPostal.value == address.iCodigoPostal">Seleccionado</span>
                    </div>
                </div>
            </div>
        </ng-container>

        <br>
        <br>
        <p>o introduce un código postal en México</p>
    </div>
    <div class="buttonModal">
        <mat-form-field class="inputsEspeciales" appearance="legacy">
            <input matInput placeholder="CP" [formControl]="codigoPostal">
        </mat-form-field>
        <button mat-raised-button type="button" class="btn btn-primary modiBton" (click)="buscarCP()">Confirmar</button>
    </div>

</div>