<div class="modal-generic">
  <div align="end">
    <button mat-icon-button mat-dialog-close color="primary"><mat-icon>close</mat-icon></button>
  </div>
  <h1 mat-dialog-title color="primary" [align]="'center'">{{ data?.title ?? 'Titulo' }}</h1>
  <mat-dialog-content class="mat-typography" style="padding-top: 0">
    <ng-container *ngIf="!data?.isUploadImg">
      <p>{{ data.text ?? 'Texto' }}</p>

      <ng-container *ngIf="data?.html">
        <div [innerHTML]="data.html"></div>
      </ng-container>
    </ng-container>

    <ng-container #uploadImg *ngIf="data?.isUploadImg">
      <div class="input-group">
        <input
          #fileInput
          style="display: none"
          [accept]="data.formatAcceptImg ? data.formatAcceptImg : 'image/*'"
          type="file"
          name="file"
          id="file"
          (change)="selectedFile($event)"
        />
        <input type="text" class="form-control" disabled [value]="dataFile?.name" />
        <div class="input-group-append">
          <button
            class="btn btn-primary"
            type="button"
            (click)="controlFile.invalid ? fileInput.click() : actionFile()"
          >
            {{ controlFile.invalid ? 'Seleccionar' : 'Aceptar' }}
          </button>
        </div>
      </div>
      <span class="text-primary">{{ data?.formatAcceptImgLabel }}</span>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="center" *ngIf="!data?.isUploadImg">
    <button mat-raised-button mat-dialog-close color="warn" *ngIf="data.actions.includes('cancel')">
      {{ data?.btnCancelText ?? 'Cancelar' }}
    </button>
    <button
      mat-raised-button
      color="primary"
      [mat-dialog-close]="true"
      *ngIf="data.actions.includes('success')"
      cdkFocusInitial
    >
      {{ data?.btnSuccessText ?? 'Aceptar' }}
    </button>
  </mat-dialog-actions>
</div>
