export enum MenuSign {
    iniciarSesion = 'signIn',
    registrarse = 'signUp',
}

export enum CatMetodoPago {
    Banregio = 1,
    MercadoPago = 2
}

export enum CatMetodoEnvio {
    Pickup = 1,
    Domocilio = 2
}