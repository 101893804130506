import { Injectable } from "@angular/core";
import { Amplify, Auth } from "aws-amplify";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";


export interface User {
  email: string;
  password: string;
  showPassword?: boolean;
  code?: string;
  name?: string;
}

@Injectable({
  providedIn: "root",
})
export class CognitoService {
  private authenticationSubject: BehaviorSubject<any>;

  constructor() {
    Amplify.configure({
      Auth: environment.cognito,
      mandatorySignIn: true,
      oauth: {
        domain: 'tiendafigueacero.auth.us-east-2.amazoncognito.com',
        redirectUri: 'https://desa.figueacero.com.mx/',
        scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: 'http://localhost:4200/',
        redirectSignOut: 'https://desa.figueacero.com.mx/logout',
        responseType: 'code',
     },
    });
    this.authenticationSubject = new BehaviorSubject<boolean>(false);
  }

  signIn(user: User): Promise<any> {
    return Auth.signIn(user.email, user.password);
  }

  signInGoogle(): void {
    Auth.federatedSignIn({ customProvider: 'Google' });
  }

  dataUser() {
    return Auth.currentUserInfo();
  }

  data() {
    return Auth.currentAuthenticatedUser();
  }

  logout() {
    return Auth.signOut();
  }
}
