import { Component, ViewChild, ElementRef, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { of, Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { MenuSign } from "src/app/models/enum.model";
import {
  Categoria,
  Product,
  ProductosCarrito,
} from "src/app/models/products.model";
import { AgradecimientoComponent } from "src/app/pages/@components-logged/@modals/agradecimiento/agradecimiento.component";
import { EligeUbiComponent } from "src/app/pages/@components-logged/@modals/elige-ubi/elige-ubi.component";
import { MisCuponesComponent } from "src/app/pages/@components-logged/@modals/modal-mis-cupones/mis-cupones.component";
import { NoDisponibleComponent } from "src/app/pages/@components-logged/@modals/no-disponible/no-disponible.component";
import { RecuperarCuentaComponent } from "src/app/pages/@components-logged/@modals/recuperar-cuenta/recuperar-cuenta.component";
import { RestrablecerContraComponent } from "src/app/pages/@components-logged/@modals/restrablecer-contra/restrablecer-contra.component";
import { CartService } from "src/app/services/cart.service";
import { NotificationsService } from "src/app/services/notifications.service";
import { ProductsService } from "src/app/services/products.service";
import { SidenavService } from "src/app/services/sidenav.service";
import { UserService } from "src/app/services/user.service";
import { SigninComponent } from "../../@modals/signin/signin.component";
import { SignupComponent } from "../../@modals/signup/signup.component";
import { ModalGenericComponent } from "src/app/shared/@modals/modal-generic/modal-generic.component";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @ViewChild("cart") btnCart: ElementRef<HTMLImageElement>;

  buttons: Array<any> = [
    { path: "/", name: "Inicio", link: "/" },
    { path: "/", name: "Nosotros", link: "/nosotros" },
    { path: "/", name: "Contacto", link: "/" },
    { path: "/", name: "Blog", link: "/" },
    { path: "/", name: "Productos", link: "/productos" },
  ];

  modals = [
    { nombre: "Elige ubicación", c: EligeUbiComponent },
    { nombre: "Inicia sesión", c: SigninComponent },
    // { nombre: 'Registrarse', c: RegistrarComponent },
    { nombre: "Recuperar cuenta", c: RecuperarCuentaComponent },
    { nombre: "Mis cupones", c: MisCuponesComponent },
    { nombre: "Restablecer password", c: RestrablecerContraComponent },
    { nombre: "Agradecimiento compra", c: AgradecimientoComponent },
    { nombre: "No disponible", c: NoDisponibleComponent },
  ];

  categoriesList: Categoria[] = [];
  productosCarrito: ProductosCarrito[] = [];
  costoTotal: number = 0;
  codigoPostal: number;

  $unsubscribe = new Subject();

  controlSearch = new FormControl(null, Validators.required);
  controlCategory = new FormControl(0, Validators.required);

  constructor(
    public dialog: MatDialog,
    private _sidenavService: SidenavService,
    public _cartService: CartService,
    public _productsService: ProductsService,
    private router: Router,
    public _userService: UserService,
    private activRoute: ActivatedRoute,
    private notifService: NotificationsService
  ) {}

  ngOnInit() {
    this.getURLParams();
    this.getCategories();
    this.obtenerCP();
    this.obtenerProductosCarrito();
    this.obtenerCarritoListener();
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.$unsubscribe.next(true);
    this.$unsubscribe.complete();
  }

  getURLParams(): void {
    this.activRoute.queryParams
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((query) => {
        if (query?.search) this.controlSearch.setValue(query.search);
        else this.controlCategory.reset(0);
      });
  }

  getCategories() {
    this._productsService
      .getCategorias()
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((categorias) => {
        this.categoriesList = categorias.reduce((acc, cat) => {
          if (!cat.sNombreCategoria.includes("pqt_")) {
            acc.push(cat);
          }
          return acc;
        }, []);
      });
  }

  openCategory(idTipo: number, idCategory: number) {
    this.router.navigate(["/products", idTipo, idCategory]);
  }

  search(idTipo: number = 1): void {
    if (this.controlSearch.value)
      this.router.navigate(["/products", idTipo, this.controlCategory.value], {
        queryParams: { search: this.controlSearch.value },
      });
    if (!this.controlSearch.value)
      this.router.navigate(["/products", idTipo, this.controlCategory.value]);
  }

  openModalGeneric(component) {
    this.dialog.open(component, {
      width: "auto",
      // maxWidth: '600px',
    });
  }

  openModal(type: "signIn" | "signUp") {
    if (type === MenuSign.registrarse) this.openModalRegister();

    if (type === MenuSign.iniciarSesion) {
      const signupDialog = this.dialog.open(SigninComponent, {
        width: "520px",
      });

      signupDialog.afterClosed().subscribe((result) => {
        const { openRegister, openForgotPassword } = result;
        if (openRegister) return this.openModalRegister();
        if (openForgotPassword) return this.openModalForgotPassword();
      });
    }
  }

  private openModalRegister() {
    const signupDialog = this.dialog.open(SignupComponent, {
      width: "500px",
      maxWidth: "500px",
      maxHeight: "95vh",
    });

    signupDialog.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  private openModalForgotPassword() {
    const signupDialog = this.dialog.open(RecuperarCuentaComponent, {
      width: "500px",
      maxWidth: "500px",
      maxHeight: "95vh",
    });

    signupDialog.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  deleteItem(item: Product) {
    const idUser = this._userService.getIdUser();
    this._cartService
      .removeFromCart(String(idUser), String(item.iIdProducto))
      .pipe(
        takeUntil(this.$unsubscribe),
        switchMap((res) => {
          this._cartService.cartAction.next("update");
          return of(res);
        })
      )
      .subscribe();
  }

  toggleMenu() {
    this._sidenavService.openClose.next(true);
  }

  openModalCP() {
    const url = this.router.url;
    console.log(url);
    if (url === "/checkout") {
      this.dialog.open(ModalGenericComponent, {
        data: {
          title: "Aviso",
          text: '',
          html: "<p>No puedes cambiar tu dirección de envío en este momento debido a que estas en un proceso de compra.</p> <p> Regresa al carrito si deseas cambiar la dirección</p>",
          actions: ["success"],
        },
        maxWidth: '450px'
      });
    } else {
      const dialog = this.dialog.open(EligeUbiComponent, {
        maxWidth: "573px",
      });
    }
  }

  logout(): void {
    this._userService.logout();
    this.router.navigate(["/"]);
    location.reload();
  }

  obtenerCP() {
    this._userService.obtenerCP.subscribe((cp) => (this.codigoPostal = cp.CP));

    if (this.codigoPostal == null) {
      this.codigoPostal = Number(localStorage.getItem("CP"));
    }
    //console.log("_getCP: ", this.codigoPostal);
  }

  obtenerCarritoListener(): void {
    this._cartService.$cartAction.subscribe((action) => {
      if (action === "update") this.obtenerProductosCarrito();
    });
  }

  private obtenerProductosCarrito() {
    const idUsuario = this._userService.getIdUser();
    this._cartService
      .obtenerCarrito(idUsuario)
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((productos) => {
        this.productosCarrito = productos.length ? productos : [];
        // console.log("productosCarrito: ", this.productosCarrito);
        this.calcularTotal();
      });
  }

  calcularTotal() {
    const totalProducto = this.productosCarrito.map(
      (producto) => producto.subtotal
    );
    for (let i = 0; i < totalProducto.length; i++) {
      this.costoTotal += totalProducto[i];
    }
  }

  goToCart(): void {
    this._userService.obtenerCP.subscribe((cp) => {
      if (cp.CP) {
        this.router.navigate(["/cart"]);
      } else {
        this.notifService.openMessage(
          "Debe elegir una ubicación para continuar"
        );
        this.openModalCP();
      }
    });
  }

  get isLogged() {
    return this._userService.isLogged();
  }
}
