import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { SidenavService } from "./services/sidenav.service";
import { MatDrawer } from "@angular/material/sidenav";
import { TestService } from "./services/test.service";
import { FacebookService, InitParams } from "ngx-facebook";
import { ProductsService } from "./services/products.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { Categoria } from "./models/products.model";
import { Router } from "@angular/router";
import { UserService } from "./services/user.service";
import { NotificationsService } from "./services/notifications.service";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { CognitoService } from "./services/cognito.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("sidenav") Sidenav: MatDrawer;
  @BlockUI() blockUI: NgBlockUI;
  buttons: Array<any> = [
    { path: "/productos", name: "Inicio", link: "/" },
    { path: "/paquetes", name: "Nosotros", link: "/nosotros" },
    // { path: "/ocasion", name: "Contacto", link: "/contacto" },
    { path: "/blog", name: "Blog", link: "/blog" },
    // { path: '/contacto', name: 'Contáctanos', link: '/'},
  ];

  buttonsProducts = [
    { name: "Lo más vendido", link: "/productos-mas-vendidos" },
    { name: "Liquidación", link: "/liquidacion" },
  ];

  categoriesList: Categoria[] = [];

  private $unsubscribe = new Subject();

  constructor(
    private _sidenavService: SidenavService,
    private facebookService: FacebookService,
    private _productsService: ProductsService,
    private router: Router,
    private userService: UserService,
    private notifService: NotificationsService,
    private _cognitoService: CognitoService
  ) {}

  ngOnInit(): void {
    this.loadingListener();
    this.getCategories();
    this.getData()
    // this.initFacebookService();
  }

  ngAfterViewInit() {
    this._sidenavService.$openClose.subscribe((resp: boolean) => {
      return this.Sidenav.toggle();
    });
  }

  ngOnDestroy() {
    this.$unsubscribe.next(true);
    this.$unsubscribe.complete();
  }

  private loadingListener(): void {
    this.notifService.$loading.subscribe((action) => {
      action ? this.blockUI.start() : this.blockUI.stop();
    });
  }

  openCategory(idTipo: number, idCategory: number) {
    this.router.navigate(["/products", idTipo, idCategory]);
  }

  nuevosProductos(idCategory: number) {
    this.router.navigate(["/nuevos-productos", idCategory]);
  }

  private getCategories() {
    this._productsService
      .getCategorias()
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((categorias) => {
        this.categoriesList = categorias.reduce((acc, cat) => {
          if (!cat.sNombreCategoria.includes("pqt_")) {
            acc.push(cat);
          }
          return acc;
        }, []);
      });
  }

  private initFacebookService(): void {
    const initParams: InitParams = {
      xfbml: true,
      version: "v3.2",
    };
    this.facebookService.init(initParams);
  }

  get _isLogged() {
    return this.userService.isLogged();
  }

  // buttons: Array<any> = [
  //   { path: '/productos', name: 'Productos'},
  //   { path: '/paquetes', name: 'Paquetes'},
  //   { path: '/ocasion', name: 'Para la ocasión'},
  //   { path: '/contacto', name: 'Contactanos'},
  // ]

  getData() {
    this._cognitoService.dataUser().then(data => {
      console.log({data})
    }).catch((e) => {
      console.log({error: e})
    })
    this._cognitoService.data().then(data => {
      console.log({data})
    }).catch((e) => {
      console.log({error: e})
    })
    // this._cognitoService.logout().then(console.log)
  }
}
