import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { RoleGuard } from "./guards/role.guard";
import { PagesComponent } from "./pages/pages.component";

const routes: Routes = [
  {
    path: "",
    component: PagesComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/@components/not-logged.module").then(
            (m) => m.NotLoggedModule
          ),
      },
      {
        path: "user",
        loadChildren: () =>
          import("./pages/@components-logged/logged.module").then(
            (m) => m.LoggedModule
          ),
        canActivate: [RoleGuard],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
