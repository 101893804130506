import { Component, OnDestroy, OnInit } from "@angular/core";
import { UserService } from "src/app/services/user.service";
import { FormControl } from "@angular/forms";
import { AddressService } from "../../../../services/address.service";
import { Address } from "../../../../models/address.model";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { SigninComponent } from "src/app/shared/@modals/signin/signin.component";
import { RecuperarCuentaComponent } from "../recuperar-cuenta/recuperar-cuenta.component";
import { SignupComponent } from "src/app/shared/@modals/signup/signup.component";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-elige-ubi",
  templateUrl: "./elige-ubi.component.html",
  styleUrls: ["./elige-ubi.component.scss"],
})
export class EligeUbiComponent implements OnInit, OnDestroy {
  codigoPostal = new FormControl();
  addressList: Address[] = [];
  $unsubscribe = new Subject();

  constructor(
    public _userSevice: UserService,
    private _addressSrv: AddressService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<EligeUbiComponent>
  ) {}

  ngOnInit() {
    this.getCP();
    this.getAddressList();
  }

  ngOnDestroy(): void {
    this.$unsubscribe.next();
    this.$unsubscribe.complete();
  }

  get isLogged() {
    return this._userSevice.isLogged();
  }

  getAddressList(): void {
    this._addressSrv
      .getAddress()
      .pipe(takeUntil(this.$unsubscribe))
      .subscribe((address) => {
        this.addressList = address;
      });
  }

  getCP(): void {
    this._userSevice.obtenerCP.subscribe((postal) => {
      this.codigoPostal.setValue(postal.CP);
    });
  }

  buscarCP() {
    const cp = this.codigoPostal.value;
    localStorage.setItem("CP", cp);
    localStorage.removeItem("idAddress");
    this._userSevice.nuevoCP = { CP: cp };
    this.dialogRef.close();
  }

  selectCP(address: Address) {
    localStorage.setItem("CP", String(address.iCodigoPostal));
    localStorage.setItem("idAddress", String(address.iIdDireccion));
    this._userSevice.nuevoCP = { CP: address.iCodigoPostal };
    this.dialogRef.close();
  }

  iniciarSesion() {
    this.dialogRef.close();
    const signupDialog = this.dialog.open(SigninComponent, {
      maxWidth: "573px",
    });

    signupDialog.afterClosed().subscribe((result) => {
      const { openRegister, openForgotPassword } = result;
      if (openRegister) return this.openModalRegister();
      if (openForgotPassword) return this.openModalForgotPassword();
    });
  }

  private openModalRegister() {
    const signupDialog = this.dialog.open(SignupComponent, {
      width: "500px",
      maxWidth: "500px",
      maxHeight: "95vh",
    });

    signupDialog.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }

  private openModalForgotPassword() {
    const signupDialog = this.dialog.open(RecuperarCuentaComponent, {
      width: "500px",
      maxWidth: "500px",
      maxHeight: "95vh",
    });

    signupDialog.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
}
