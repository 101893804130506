<div class="contenedorModal">
    <div class="cabezal">
        <div class="boton_cerrar">
            <!-- <i class="fas fa-times" mat-dialog-close>X</i> -->
            <button class="main__btn-close" mat-dialog-close mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <h3 class="encabezado">GRACIAS POR TU PEDIDO</h3>
    </div>
    <div class="textoDelModal">
        <p>Te hemos enviado la confirmación de tu pedido a tu correo electrónico</p>
              
    </div>
    <div class="buttonModal">
        <button mat-raised-button type="button" class="btn btn-primary modiBton">Aceptar</button>


    </div>

</div>