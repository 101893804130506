import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject, Subscriber, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Product, ItemCart, ProductosCarrito } from "../models/products.model";
import { map } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  CartAdd,
  CartCostoEnvio,
  CartCostoSubtotal,
  CartFaltanteCategoria,
  CartTypes,
  DataCart,
  UsoCFDI,
  RegFiscal,
} from "../models/cart.model";
import { Result } from "../models/common.model";
import { NotificationsService } from "./notifications.service";

let products = JSON.parse(localStorage.getItem("cart")) || [];

@Injectable({
  providedIn: "root",
})
export class CartService {
  urlAWS = environment.urlAWS;
  counterCart: BehaviorSubject<number> = new BehaviorSubject(0);
  $counterCart = this.counterCart.asObservable();

  cartItems: BehaviorSubject<any[]> = new BehaviorSubject([]);
  observer: Subscriber<{}>;

  cartAction: BehaviorSubject<CartTypes> = new BehaviorSubject("");
  $cartAction = this.cartAction.asObservable();

  // Shipping
  shipping: number = 100;

  constructor(
    private http: HttpClient,
    private _notifService: NotificationsService
  ) {
    this.cartItems.subscribe((_products) => (_products = products));

    // console.log("Cart::", products);
  }

  public getItems(): Observable<ItemCart[]> {
    return of(products);
  }

  // addToCart(itemCart: Product, quantity: number): ItemCart | boolean {
  //   let item: ItemCart | boolean = false;
  //   // If Products exist
  //   const productExist = products.find((items, index) => {
  //     if (items.itemCart.iIdProducto === itemCart.iIdProducto) {
  //       const qty = products[index].quantity + quantity;
  //       const stock = this.calculateStockCounts(products[index], quantity);
  //         if (qty !== 0 && stock) {
  //             products[index].quantity = qty;
  //             this.openSnackBar('Se añadió al carrito', 'cerrar');
  //         }
  //       return true;
  //     }
  //   });
  //     // If Products does not exist (Add New Products)
  //   if (!productExist) {
  //     item = { itemCart, quantity };
  //     products.push(item);
  //     this.openSnackBar('Se añadió al carrito', 'cerrar');
  //   }
  //   localStorage.setItem('cart', JSON.stringify(products));
  //   return products;
  // }

  addToCartFromDetails(
    itemCart: Product,
    quantity: number
  ): ItemCart | boolean {
    let item: ItemCart | boolean = false;
    // If Products exist
    const productExist = products.find((items, index) => {
      if (items.itemCart.iIdProducto === itemCart.iIdProducto) {
        const qty = quantity;
        if (qty !== 0) {
          products[index].quantity = qty;
          this._notifService.openMessage("Se añadió al carrito");
        }
        return true;
      }
    });
    // If Products does not exist (Add New Products)
    if (!productExist) {
      item = { itemCart, quantity };
      products.push(item);
    }
    localStorage.setItem("cart", JSON.stringify(products));
    return products;
  }

  // Calculate Product stock Counts
  calculateStockCounts(product: any, quantity): any | Boolean {
    const qty = product.quantity + quantity;
    const stock = product.itemCart.stock;
    console.log(stock, qty);

    if (stock < qty) {
      alert("No puedes añadir mas productos de los que hay en stock");
      return false;
    }
    return true;
  }

  // Update Cart Value
  updateCartQuantity(product: Product, quantity: number): ItemCart | boolean {
    return products.find((items, index) => {
      if (items.itemCart.iIdProducto == product.iIdProducto) {
        const qty = products[index].quantity + quantity;
        const stock = this.calculateStockCounts(products[index], quantity);
        if (qty !== 0 && stock) {
          products[index].quantity = qty;
        }
        localStorage.setItem("cart", JSON.stringify(products));
        return true;
      }
    });
  }

  // Set quantity Cart Value
  setQuantityItem(product: Product, quantity: number): ItemCart | boolean {
    return products.find((items, index) => {
      if (items.itemCart.iIdProducto == product.iIdProducto) {
        const qty = quantity;
        //  const stock = this.calculateStockCounts(products[index], quantity);
        if (qty !== 0) {
          products[index].quantity = qty;
        }
        localStorage.setItem("cart", JSON.stringify(products));
        return true;
      }
    });
  }

  // Removed in cart
  removeFromCart(iIdUsuario: string, iIdProducto: string) {
    const req: CartAdd = {
      iIdUsuario,
      iIdProducto,
      iCantidad: "0",
    };
    return this.http.post<Result>(this.urlAWS + "/carritocompras", req);
  }

  removeFromCartStorage(item: Product) {
    if (!item || item === undefined) return false;
    var index: number = null;

    products.findIndex((element: ItemCart, i) => {
      if (
        element.itemCart.iIdProducto.toString() == item.iIdProducto.toString()
      )
        index = i;
    });

    products.splice(index, 1);
    localStorage.setItem("cart", JSON.stringify(products));
  }

  getCartById(): Observable<any> {
    let idUser = localStorage.getItem("idUser");
    return this.http.get(environment.url + "cart/getCartByUserId/" + idUser);
  }

  getCounterItems(): Observable<Array<ItemCart>> {
    return of(products.length);
  }

  // Total amount
  getTotalAmount(): Observable<number> {
    return of(0);
    // return this.cartItems.pipe(
    //   map((product: ItemCart[]) => {
    //     return products.reduce((prev, curr: ItemCart) => {
    //       return prev + curr.itemCart.dPrecioUnitario * curr.quantity;
    //     }, 0);
    //   })
    // );
  }

  // Total amount with shipping
  // getTotalAmountWithShipping(): Observable<number> {
  //   let total = this.cartItems.pipe(map((product: ItemCart[]) => {
  //       return products.reduce((prev, curr: ItemCart) => {
  //           return prev + curr.itemCart.dPrecioUnitario * curr.quantity;
  //       }, 0);
  //   }));
  //   return total + this.shipping;
  // }

  resetCart() {
    products = [];
    localStorage.setItem("cart", JSON.stringify(products));
  }

  agregarCarrito(req: CartAdd): Observable<Result> {
    return this.http.post<Result>(this.urlAWS + "/carritocompras", req);
  }

  obtenerCarrito(idUsuario: number): Observable<Product[]> {
    return this.http.get<Product[]>(
      this.urlAWS + `/carritocompras?idusuario=${idUsuario}`
    );
  }

  agregarCarritoStorage(valorProducto: any, cantidad: number) {
    const productExist = products.find((items, index) => {
      if (items.producto.iIdProducto === valorProducto.iIdProducto) {
        const qty = products[index].iCantidad + cantidad;
        if (qty !== 0) {
          products[index].iCantidad = qty;
          this._notifService.openMessage("Se añadió al carrito");
          console.log("producto actualizado");
        }
        return true;
      }
    });

    if (!productExist) {
      const item = {
        producto: valorProducto,
        iCantidad: cantidad,
      };
      products.push(item);
      this._notifService.openMessage("Se añadió al carrito");
      console.log("nuevo producto", item);
    }
    localStorage.setItem("cart", JSON.stringify(products));
  }

  getFaltanteCategoria(idUsuario: number): Observable<CartFaltanteCategoria[]> {
    return this.http.get<CartFaltanteCategoria[]>(
      this.urlAWS +
        `/procesocompra/descuentos/porcategoria?idcarrito=${idUsuario}`
    );
  }

  getCostoEnvio(cp: string, idUsuario: number): Observable<CartCostoEnvio[]> {
    return this.http.get<CartCostoEnvio[]>(
      this.urlAWS +
        `/procesocompra/costoenvio?codigopostal=${cp}&iIdUsuario=${idUsuario}`
    );
  }

  getAplicaSubtotal(idCarrito: number, cp: number, idCupon?: number): Observable<CartCostoSubtotal[]> {
    let params = {
      iIdCarrito: idCarrito,
      sCodigoPostal: cp,
      iIdCupon: idCupon ?? '',
    };
    if (!idCupon) delete params.iIdCupon;
    return this.http.get<CartCostoSubtotal[]>(
      this.urlAWS +
        `/procesocompra/descuentos/aplicasubtotal`,
      { params }
    );
  }

  setDataCart(dataCart: DataCart): void {
    const str = JSON.stringify(dataCart);
    localStorage.setItem("dataCart", str);
  }

  clearDataCart(): void {
    localStorage.removeItem("dataCart");
  }

  //obteniendo los datos de CFDI

  getCFDI(): Observable<UsoCFDI[]> {
    return this.http.get<UsoCFDI[]>(
      this.urlAWS + `/catalogo?catname=cat_usocfdi`
    );
  }

  //obteniendo los datos de Regimen fiscal

  getRegimenFiscal(): Observable<RegFiscal[]> {
    return this.http.get<RegFiscal[]>(
      this.urlAWS + `/catalogo?catname=cat_regimenfiscal`
    );
  }
}
