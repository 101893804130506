<div class="contenedorModal">
    <div class="cabezal">
        <div class="boton_cerrar">
            <!-- <i class="fas fa-times" mat-dialog-close>X</i> -->
            <button class="main__btn-close" mat-dialog-close mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <h3 class="encabezado">MIS CUPONES</h3>
    </div>

    <div class="contenedorCupones">

        <h4 *ngIf="coupons.length === 0">No se encontraron cupones asociados a tu cuenta</h4>

        <ng-container *ngIf="coupons.length > 0">
            <mat-radio-group aria-label="Selecciona un cupon" [formControl]="controlCupon">
                <div class="cupon" *ngFor="let c of coupons">
                    <div class="txtCupon">
                        <div class="row">
                            <div class="col-9">
                                <span class="tituloCupon">
                                    <!-- ENVÍO GRATIS -->
                                </span>
                            </div>
                            <div class="col-3 modiCupon1">
                                <span class="claveCupon">
                                    {{c.sCodigoCupon}}
                                </span>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-9">
                                <div class="row">
                                    <span class="descripcionCupon">
                                        {{c.sConcepto}}
                                    </span>
                                </div>
                                <div class="row">
                                    <span class="descripcionCupon">
                                        {{(c?.dFechaInicio | date: 'dd-MM-YYYY') + ' - ' + (c?.dFechaFin | date:
                                        'dd-MM-YYYY')}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-3 modiCupon1">
                                <mat-radio-button color="primary" [value]="c"></mat-radio-button>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-radio-group>
        </ng-container>

    </div>

    <div class="buttonModal">
        <button *ngIf="coupons.length > 0" color="primary" mat-raised-button type="button"
            [disabled]="controlCupon.invalid" (click)="saveCoupon()">Aplicar</button>
        <button *ngIf="coupons.length === 0" mat-raised-button color="accent" type="button"
            [matDialogClose]>Cerrar</button>
    </div>

</div>