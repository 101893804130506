import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
  AbstractControl,
  ValidationErrors,
} from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UserService } from "src/app/services/user.service";
import Swal from "sweetalert2";
import { FacebookService, InitParams } from 'ngx-facebook';
import { Md5 } from 'ts-md5/dist/md5';

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.scss"],
})
export class SignupComponent implements OnInit, OnDestroy {
  form: FormGroup;
  subs = new Subscription();

  constructor(
    private fb: FormBuilder,
    public modal: MatDialog,
    private _userService: UserService,
    private dialogRef: MatDialogRef<SignupComponent>,
    private facebookService: FacebookService,
  ) {}

  ngOnInit(): void {
    this.formInit();
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  private formInit() {
    this.form = this.fb.group(
      {
        nombre: [null],
        apellidos: [null],
        sCorreoElectronico: [null, Validators.required, Validators.email],
        sPassword: [null, Validators.required],
        repeatPassword: [null, Validators.required],
        telefono: [null, Validators.required],
      },
      { validators: checkPasswords }
    );
  }

  register() {
    const { sCorreoElectronico, sPassword } = this.form.getRawValue();
    const requestForm = {
      sCorreoElectronico,
      sPassword: Md5.hashStr(sPassword),
    }

    this.subs = this._userService.signUp(requestForm).subscribe((resp) => {
      const { error } = resp;

      if (error) return Swal.fire("Alerta", "Ha ocurrido un error", "error");

      Swal.fire("", "Registro exitoso", "success").then(() => {
        this.dialogRef.close();
      });
    });
  }

  initFacebookService(): void {
    const initParams: InitParams = {
      xfbml: true,
      version: "v3.2",
    };
    this.facebookService.init(initParams);
  }

  inicioGoogle(){
    console.log('mensaje para inicioGoogle');
  }


  get nombre(): AbstractControl {
    return this.form.get("nombre");
  }
  get apellidos(): AbstractControl {
    return this.form.get("apellidos");
  }
  get correo(): AbstractControl {
    return this.form.get("sCorreoElectronico");
  }
  get password(): AbstractControl {
    return this.form.get("sPassword");
  }
  get rPassword(): AbstractControl {
    return this.form.get("repeatPassword");
  }
}

function checkPasswords(group: AbstractControl): ValidationErrors | null {
  let pass = group.get("sPassword").value;
  let confirmPass = group.get("repeatPassword").value;
  return pass === confirmPass ? null : { notSame: true };
}
