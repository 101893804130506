import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./pages/@components/home/home.component";
import { ProductDetailComponent } from "./pages/@components/product-detail/product-detail.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material.module";
import { ReactiveFormsModule } from "@angular/forms";
import { StockSelectPipe } from "./shared/pipes/stock-select.pipe";
import { ModalsModule } from "./shared/@modals/modals.module";
import { PagesComponent } from "./pages/pages.component";
import { HttpClientModule } from "@angular/common/http";
import { FacebookModule } from "ngx-facebook";
import { SharedModule } from "./shared/shared.module";
import { ReCaptchaModule } from "angular-recaptcha3";
import { BlockUIModule } from "ng-block-ui";
import { CreditCardDirectivesModule } from "angular-cc-library";
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from "@abacritt/angularx-social-login";

const pipes = [StockSelectPipe];
// const config: SocketIoConfig = {
//   url: "wss://r54ztucci9.execute-api.us-east-2.amazonaws.com",
//   options: {

//     // transports: ['websocket'],
//     // withCredentials: false,
//     path: '/production'
//   },
// };

@NgModule({
  declarations: [AppComponent, PagesComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    SharedModule,
    MaterialModule,
    CreditCardDirectivesModule,
    SocialLoginModule,
    BlockUIModule.forRoot({
      delayStop: 1000,
    }),
    // SocketIoModule.forRoot(config),
    // FacebookModule.forRoot(),
    ModalsModule,
    ReCaptchaModule.forRoot({
      invisible: {
        sitekey: "6LdopFQeAAAAAGrLTJteMgh9XPlxZ7wODc1iUR9l",
      },
      normal: {
        sitekey: "6LdopFQeAAAAAGrLTJteMgh9XPlxZ7wODc1iUR9l",
      },
      language: "es",
    }),
  ],
  providers: [
    ...pipes,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
