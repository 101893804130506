<block-ui>

  <div class="fb-customerchat" page_id="246642606177113">
  </div>

  <!-- Messenger plugin de chat Code -->
  <div id="fb-root"></div>

  <!-- Your plugin de chat code -->
  <!-- <div id="fb-customer-chat" class="fb-customerchat">
</div> -->

  <!-- <script>
  var chatbox = document.getElementById('fb-customer-chat');
  chatbox.setAttribute("page_id", "100793015512880");
  chatbox.setAttribute("attribution", "biz_inbox");

  window.fbAsyncInit = function() {
    FB.init({
      xfbml            : true,
      version          : 'v12.0'
    });
  };

  (function(d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s); js.id = id;
    js.src = 'https://connect.facebook.net/es_LA/sdk/xfbml.customerchat.js';
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));
</script> -->

  <mat-drawer-container hasBackdrop="true">
    <mat-drawer #sidenav mode="over" class="menuOculto">

      <!-- Buttons menu -->
      <div class="menu">
        <button class="btn-close" color="primary" mat-icon-button (click)="Sidenav.close()">
          <mat-icon>close</mat-icon>
        </button>

        <div class="imgLogo">
          <img src="../assets/iconos/logo-texto.png" style="width: 100%;" alt="">
        </div>

        <h4 class="color-primario">Figueacero</h4>
        <p *ngFor="let btn of buttons" [routerLink]="[btn.link]" routerLinkActive="router-link-active"
          (click)="Sidenav.close()">{{ btn.name }}</p>
        <!-- <p *ngIf="_isLogged" routerLink="/account" (click)="Sidenav.close()">Mi cuenta</p> -->


        <h4 class="color-primario">Productos</h4>
        <p *ngFor="let c of categoriesList" (click)="openCategory(1, c.iIdCategoria); Sidenav.close()">{{
          c.sNombreCategoria }}</p>

        <!-- <h4 class="color-primario">Nuevos Productos</h4>
        <p *ngFor="let c of categoriesList" (click)="nuevosProductos(c.iIdCategoria); Sidenav.close()">{{
          c.sNombreCategoria }}</p>


        <h4 class="color-primario">Tendencia</h4>
        <p *ngFor="let itemProducts of buttonsProducts" [routerLink]="[itemProducts.link]"
          routerLinkActive="router-link-active" (click)="Sidenav.close()">
          {{ itemProducts.name }}
        </p> -->

      </div>
    </mat-drawer>
    <mat-drawer-content>

      <router-outlet></router-outlet>

    </mat-drawer-content>
  </mat-drawer-container>

</block-ui>