import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject } from "rxjs";
import {
  ModalGeneric,
  ModalGenericComponent,
} from "../shared/@modals/modal-generic/modal-generic.component";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  loading = new BehaviorSubject(false);
  $loading = this.loading.asObservable();

  constructor(private _snackBar: MatSnackBar, private dialog: MatDialog) {}

  openMessage(message: string = "Alerta", duration = 4000) {
    this._snackBar.open(message, "", {
      horizontalPosition: "center",
      verticalPosition: "bottom",
      duration,
    });
  }

  loader(action: boolean): void {
    this.loading.next(action);
  }

  genericModalAlert(title: string, text: string) {
    const data: ModalGeneric = {
      title,
      text,
      actions: ["success"],
    };
    return this.dialog.open(ModalGenericComponent, {
      data,
    });
  }
}
