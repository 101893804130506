import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recuperar-cuenta',
  templateUrl: './recuperar-cuenta.component.html',
  styleUrls: ['./recuperar-cuenta.component.scss']
})
export class RecuperarCuentaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
