<div class="contenedorModal">
    <div class="cabezal">
        <div class="boton_cerrar">
            <button class="main__btn-close" mat-dialog-close mat-icon-button>
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <h3 class="encabezado">REGÍSTRATE</h3>
    </div>
    <div class="textoDelModal">
        <form class="formulario" [formGroup]="form">
            <mat-form-field appearance="legacy">
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="Ingrese su nombre" formControlName="nombre" required>
                <mat-error>Campo requerido</mat-error>
            </mat-form-field>

            <mat-form-field appearance="legacy">
                <mat-label>Apellidos</mat-label>
                <input matInput placeholder="Ingrese su apellido" formControlName="apellidos" required>
                <mat-error>Campo requerido</mat-error>
            </mat-form-field>

            <mat-form-field appearance="legacy">
                <mat-label>E-mail</mat-label>
                <input type="email" matInput placeholder="Ingrese su e-mail" formControlName="sCorreoElectronico" required>
                <mat-error>Campo incorrecto</mat-error>
            </mat-form-field>

            <mat-form-field appearance="legacy">
                <mat-label>Contraseña</mat-label>
                <input type="password" matInput placeholder="Ingrese una contraseña" formControlName="sPassword" required>
                <mat-error>Campo incorrecto</mat-error>
            </mat-form-field>

            <mat-form-field appearance="legacy">
                <mat-label>Confirmar contraseña</mat-label>
                <input type="password" matInput placeholder="Ingrese de nuevo su contraseña" formControlName="repeatPassword">
                <mat-error *ngIf="rPassword?.errors?.required">Campo requerido</mat-error>
                <mat-hint *ngIf="rPassword.touched && form?.errors?.notSame" style="color: #E74C3C">Las contraseñas no coinciden</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="legacy">
                <mat-label>Teléfono</mat-label>
                <input type="tel" matInput placeholder="Ingrese su teléfono" required formControlName="telefono">
                <mat-error>Campo requerido</mat-error>
            </mat-form-field>


        </form>
        <div class="iconosRedes">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" viewBox="0 0 112.196 112.196" style="enable-background:new 0 0 112.196 112.196; cursor: pointer"
                xml:space="preserve" (click)="initFacebookService()">
                <g>
                    <circle style="fill:#3B5998;" cx="56.098" cy="56.098" r="56.098" />
                    <path style="fill:#FFFFFF;"
                        d="M70.201,58.294h-10.01v36.672H45.025V58.294h-7.213V45.406h7.213v-8.34
		c0-5.964,2.833-15.303,15.301-15.303L71.56,21.81v12.51h-8.151c-1.337,0-3.217,0.668-3.217,3.513v7.585h11.334L70.201,58.294z" />
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
            </svg>

            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px" y="0px" width="510px" height="510px" viewBox="0 0 510 510"
                style="enable-background:new 0 0 510 510; cursor: pointer" xml:space="preserve" (click)="inicioGoogle()">
                <g>
                    <g id="glass">
                        <path d="M286.875,229.5v63.75h150.45c-15.3,89.25-86.7,153-175.95,153c-104.55,0-191.25-86.7-191.25-191.25
			s86.7-191.25,191.25-191.25c53.55,0,99.45,22.95,132.6,58.65l45.9-45.9c-45.9-45.9-107.1-76.5-178.5-76.5
			c-140.25,0-255,114.75-255,255s114.75,255,255,255s242.25-114.75,242.25-255v-25.5H286.875z" />
                    </g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
                <g>
                </g>
            </svg>

        </div>
    </div>
    <div class="buttonModal">
        <button mat-raised-button type="button" color="primary" class="modiBtn" (click)="register()"
            [disabled]="form.invalid">Crear Cuenta</button>
    </div>

</div>