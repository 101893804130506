import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, pluck } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Result } from "../models/common.model";
import { Coupon } from "../models/coupon.model";
import { GenericResponse } from "../models/products.model";
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root",
})
export class CouponService {
  url = environment.url;
  urlAWS = environment.urlAWS;

  constructor(private http: HttpClient, private userService: UserService) {}

  getCoupons(): Observable<Coupon[]> {
    const idUser = this.userService.getIdUser();
    return this.http
      .get<Coupon[]>(this.url + `cuponesusr?iIdUsuario=${idUser}`)
      .pipe(
        pluck("resultDto", "sDetalle"),
        catchError<[], Observable<[]>>((err) => of([]))
      );
  }

  // updateUserData(userData: UpdateUserData) {
  //   const req: UpdateUserData = {
  //     ...userData,
  //     iIdUsuario: this.getIdUser(),
  //     sCorreo: this.getEmailUser(),
  //   };
  //   return this.http.put<Result>(this.urlAWS + "/userdata", req);
  // }

  addCoupon(sCodigoCupon: string): Observable<GenericResponse<number>> {
    const idUser = this.userService.getIdUser();
    return this.http.put<GenericResponse<number>>(
      this.url +
        `registracupon?iIdUsuario=${idUser}&sCodigoCupon=${sCodigoCupon}`,
      {}
    );
  }
}
