import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  CodigoPostal,
  signIn,
  SignInResponse,
  UpdateUserData,
  UserData,
} from "../models/user.model";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable, of } from "rxjs";
import { pluck, catchError } from "rxjs/operators";
import * as cryptoJS from "crypto-js";
import { CartTypes } from "../models/cart.model";
import { Result } from "../models/common.model";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private compartirCP: BehaviorSubject<CodigoPostal> =
    new BehaviorSubject<CodigoPostal>({ CP: null });
  $compartirCP = this.compartirCP.asObservable();
  urlAWS = environment.urlAWS;

  cpAction: BehaviorSubject<CartTypes> = new BehaviorSubject("");
  $cpAction = this.cpAction.asObservable();

  constructor(
    private http: HttpClient //  private _userService: UserService
  ) {
    const postalCode = Number(localStorage.getItem("CP"));
    this.compartirCP.next({ CP: postalCode });
  }

  isLogged(): boolean {
    const token = localStorage.getItem("token");
    const idUsuario = localStorage.getItem("idUsuario");
    if (token?.length > 0 && idUsuario) return true;
    else return false;
  }

  logout(): void {
    localStorage.removeItem("token");
    localStorage.removeItem("idUsuario");
  }

  setCredentials(
    sToken: string,
    idUsuarioEncrypt: string,
    email: string
  ): void {
    localStorage.setItem("token", sToken);
    localStorage.setItem("idUsuario", idUsuarioEncrypt);
    localStorage.setItem("email", email);
  }

  signIn(req: signIn): Observable<SignInResponse> {
    return this.http
      .get(
        environment.url +
          `login?sCorreoElectronico=${req.sCorreoElectronico}&sPassword=${req.sPassword}`
      )
      .pipe(
        pluck("resultDto", "sDetalle"),
        catchError<SignInResponse, Observable<SignInResponse>>((error) =>
          of({
            iEstatus: null,
            iIdUsuario: 0,
            iTipoRedSocial: null,
            sCorreoElectronico: null,
            sIdRedSocial: null,
            sPassword: null,
            sToken: null,
            error: error,
          })
        )
      );
  }

  signUp(req: signIn): Observable<any> {
    return this.http.get(
      environment.url +
        `creaCliente?sCorreoElectronico=${req.sCorreoElectronico}&sPassword=${req.sPassword}`
    );
  }

  desencriptar(code: string) {
    let bytes = cryptoJS.AES.decrypt(code, environment.secretKey);
    let ci = bytes.toString(cryptoJS.enc.Utf8);
    return ci;
  }

  getIdUser(): number {
    let idCliente = localStorage.getItem("idUsuario");
    let bytes = idCliente
      ? cryptoJS.AES.decrypt(idCliente, environment.secretKey)
      : null;
    if (bytes) {
      let idClienteResult = bytes.toString(cryptoJS.enc.Utf8);
      return Number(idClienteResult);
    }
    // alert('No existe usuario loggeado');
    return;
  }

  getEmailUser(): string {
    const email = localStorage.getItem("email");
    return email;
  }
  // ** POSTAL CODE

  get obtenerCP() {
    return this.$compartirCP;
  }

  set nuevoCP(cp: CodigoPostal) {
    this.compartirCP.next(cp);
    this.cpAction.next("update");
  }

  setMetodoEnvio(metodo: string) {
    localStorage.setItem("metodoEnvio", metodo);
  }

  getMetodoEnvio() {
    return localStorage.getItem("metodoEnvio");
  }

  getDatosUsuario(): Observable<UserData[]> {
    const email = this.getEmailUser();
    const idUser = this.getIdUser();
    return this.http.get<UserData[]>(
      environment.urlAWS + `/userdata?iIdUsuario=${idUser}&sCorreo=${email}`
    );
  }

  updateUserData(userData: UpdateUserData) {
    const req: UpdateUserData = {
      ...userData,
      iIdUsuario: Number(this.getIdUser()),
      sCorreo: this.getEmailUser(),
    };
    return this.http.put<Result>(this.urlAWS + "/userdata", req);
  }

  getCpStorage(): number {
    return Number(localStorage.getItem("CP"));
  }
}
