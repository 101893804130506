import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { UserService } from "src/app/services/user.service";
import * as cryptoJS from "crypto-js";
import Swal from "sweetalert2";
import { FacebookService, InitParams } from "ngx-facebook";
import { Md5 } from "ts-md5/dist/md5";
import Auth from "@aws-amplify/auth";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { CognitoService } from "src/app/services/cognito.service";
import { environment } from "src/environments/environment";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

// declare const google: any;
@Component({
  selector: "app-signin",
  templateUrl: "./signin.component.html",
  styleUrls: ["./signin.component.scss"],
})
export class SigninComponent implements OnInit, AfterViewInit, OnDestroy {
  form: FormGroup;
  auth2: any;
  @ViewChild("loginRef", { static: true }) loginElement: ElementRef;
  $unsubscribe = new Subject();

  constructor(
    private fb: FormBuilder,
    public modal: MatDialog,
    public dialogRef: MatDialogRef<SigninComponent>,
    public _userService: UserService,
    private facebookService: FacebookService,
    private _cognitoService: CognitoService
  ) {}

  ngOnInit() {
    // this.googleAuthSDK();
    this.formInit();
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
      this.$unsubscribe.next();
      this.$unsubscribe.complete();
  }

  handleCredentialResponse(response: any) {
    console.log({ response });
    const data = { idToken: response.credential, oauth: "v3" };
    this.loginCheckSocial(data);
  }

  loginCheckSocial(data: any) {
    console.log({ data });
  }

  formInit() {
    this.form = this.fb.group({
      sCorreoElectronico: [null, [Validators.required, Validators.email]],
      sPassword: [null, Validators.required],
    });
  }

  ingresar() {
    let req = this.form.value;
    req = {
      ...req,
      sPassword: Md5.hashStr(req.sPassword),
    };

    this._userService.signIn(req).pipe(takeUntil(this.$unsubscribe)).subscribe((resp) => {
      const { iIdUsuario, sToken, error } = resp;

      if (error) return Swal.fire("Alerta", "Ha ocurrido un error", "error");

      if (iIdUsuario === 0)
        return Swal.fire("Alerta", "Las credenciales son incorrectas", "error");

      let idUsuarioEncrypt = cryptoJS.AES.encrypt(
        iIdUsuario.toString(),
        environment.secretKey
      ).toString();

      this._userService.setCredentials(
        sToken,
        idUsuarioEncrypt,
        req.sCorreoElectronico
      );

      Swal.fire("", "Inicio de sesión exitoso", "success").then(() => {
        window.location.reload();
      });
    });
  }

  closeModal(openRegister = false, openForgotPassword = false) {
    this.dialogRef.close({ openRegister, openForgotPassword });
  }

  initFacebookService(): void {
    const initParams: InitParams = {
      xfbml: true,
      version: "v3.2",
    };
    this.facebookService.init(initParams);
  }

  async loginGoogle() {
    // const resp = await this._cognitoService.signIn({email: 'jared123@yopmail.com', password: '03071418Jared.'})
    // console.log({ resp });
    this._cognitoService.signInGoogle();
  }

  get correo(): AbstractControl {
    return this.form.get("sCorreoElectronico");
  }
  get password(): AbstractControl {
    return this.form.get("sPassword");
  }
}
