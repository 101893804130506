import { Component } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs/operators";
@Component({
  selector: "app-pages",
  templateUrl: "./pages.component.html",
  styleUrls: ["./pages.component.scss"],
})
export class PagesComponent {
  hideFooter: boolean;
  constructor(private router: Router) {
    this.validateRoute();
  }

  private validateRoute() {
    this.router.events
      .pipe(filter((f) => f instanceof NavigationEnd))
      .subscribe((nav: NavigationEnd) => {
        if (nav.url === "/blog") this.hideFooter = true;
        else this.hideFooter = false;
      });
  }
}
